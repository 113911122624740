import IconButton from "@mui/material/IconButton";

export function NewActionButton({ ...props }) {
    return <IconButton
        title="New"
        data-cy="new-action-button"
        className="fa fa-plus"
        size="small"
        sx={{ color: "primary.main" }}
        {...props}
    />;
};

export function EditActionButton({ ...props }) {
    return <IconButton
        title="Edit"
        data-cy="edit-action-button"
        className="fa fa-pencil"
        size="small"
        sx={{ color: "goldenrod" }}
        {...props}
    />;
};

export function DeleteActionButton({ ...props }) {
    return <IconButton
        title="Delete"
        className="fa fa-trash-alt"
        size="small"
        data-cy="delete-action-button"
        {...props}
    />;
};

export function CopyRefButton({ info, ...props }) {
    function handleCopyAction() {
        navigator.clipboard.writeText(info);
    };

    return (<IconButton
        className="fa fa-clipboard"
        size="small"
        color="inherit"
        {...props}
        onClick={handleCopyAction}
    />);
};
